<template>
  <div class="my-order">
    <el-row>
      <ai-breadcrumb />
    </el-row>
    <el-col class="form" :offset="4" :span="16">
      <el-card class="profile-card" shadow="hover">
        <div slot="header">
          <span>{{ $t("order.myServices") }}</span>
          <el-button :disabled="!allowTrail" type="primary" size="small" style="float: right; margin-top: -7px"
            @click="dialogVisible = true">{{ $t("order.applyFreeTrail") }}</el-button>
        </div>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column :label="$t('order.service')" width="180">
            <template slot-scope="scope">
              {{ serviceTitle(scope.row.serviceCode) }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('order.level')"><template slot-scope="scope">
              {{
                scope.row.level === "basic"
                ? $t("order.basic")
                : scope.row.level === "advanced"
                  ? $t("order.advanced")
                  : scope.row.level === "premium"
                    ? $t("order.premium")
                    : scope.row.level
              }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('order.status')">
            <template slot-scope="scope">
              {{
                scope.row.status === "applied"
                ? $t("order.applied")
                : scope.row.status === "approved"
                  ? $t("order.approved")
                  : scope.row.status === "rejected"
                    ? $t("order.rejected")
                    : scope.row.status
              }}
            </template></el-table-column>
          <el-table-column :label="$t('order.expireAt')" width="180">
            <template slot-scope="scope">
              <i class="el-icon-time"></i>
              <span style="margin-left: 10px">{{
                scope.row.chargeMode === "period"
                ? scope.row.expireAt
                : "" | displayTime()
              }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('order.remainImageCount')" width="160">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{
                scope.row.chargeMode === "usage" ? scope.row.remainTimes : "-"
              }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </el-col>
    <el-dialog :title="$t('order.selectService')" :visible.sync="dialogVisible">
      <el-checkbox-group class="apply-content" v-model="selectedCodes">
        <el-checkbox v-for="item in hasCodes" :label="item.code" :disabled="item.has" :key="item.code">{{
          serviceTitle(item.code) }}</el-checkbox>
      </el-checkbox-group>
      <span slot="footer">
        <el-button size="small" @click="dialogVisible = false">{{
          $t("common.cancel")
        }}</el-button>
        <el-button type="primary" size="small" @click="confirmApply" :disabled="!selectedCodes.length">{{ $t("common.ok")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import AiBreadcrumb from "@/components/AiBreadcrumb";
import { fetchUserServices, createTrial } from "@/api/user";
import { serviceI18n } from "@/filters";

export default {
  components: {
    AiBreadcrumb,
  },
  data() {
    return {
      allowTrail: false,
      tableData: [],
      dialogVisible: false,
      hasCodes: [
        { code: "tagger", has: false },
        { code: "analysis", has: false },
        { code: "data", has: false },
        { code: "visual", has: false },
        { code: "report", has: false },
        { code: "nlp", has: false },
        { code: "gpt", has: false },
        { code: "gptDesigner", has: false },
        { code: "gptMorph", has: false },
        { code: "gptConcepter", has: false },
        { code: "globalTrend", has: false },
        { code: "aeye", has: false },
      ],
      selectedCodes: [],
    };
  },
  created() {
    this.fetchList();
  },
  methods: {
    fetchList() {
      fetchUserServices().then((response) => {
        if (response.success) {
          this.tableData = response.result.items || [];
          for (let i = 0; i < this.hasCodes.length; i++) {
            for (let j = 0; j < this.tableData.length; j++) {
              if (this.hasCodes[i].code === this.tableData[j].serviceCode) {
                this.hasCodes[i].has = true;
                break;
              }
            }
          }
          let allow = false;
          for (let i = 0; i < this.hasCodes.length; i++) {
            if (!this.hasCodes[i].has) {
              allow = true;
              break;
            }
          }
          this.allowTrail = allow;
        }
      });
    },
    confirmApply() {
      this.dialogVisible = false;
      createTrial({ serviceCodes: this.selectedCodes }).then((response) => {
        if (response.success) {
          this.$notify({
            title: this.$t("common.applySucc"),
            type: "success",
          });
          this.fetchList();
        }
      });
    },
    serviceTitle(code) {
      return this.$t(serviceI18n(code))
    }
  },
};
</script>

<style lang="scss" scoped>
.my-order {
  margin: 10px 20px;

  .form {
    padding-top: 5vh;
  }

  .apply-content {
    margin: 20px 0;
    line-height: 26px;
  }
}
</style>